<template lang="pug">
	#traffic-sources-root
		#traffic-sources-body
			el-row.text-letf
				el-form()
					el-row.text-left
						h4 Traffic Sources
						p Here you can find the traffic sources that are supported by the system. You can add, remove or update the traffic sources, as well as the types of them.
					el-row.mt-2(:gutter="24")
						el-col(:span="24")
							el-form-item(label="Templates")
								el-button(type="info" icon="el-icon-plus" @click="toggleModal(0)") Add

					el-row.mt-2(:gutter="12")
						el-col(:xs="12" :sm="6")
							el-form-item(label="Search...")
								el-input(placeholder="Search By Keyword..."  v-model="paginate.keyword", v-debounce:300ms="fetchData")

						el-col(:xs="12" :sm="6")
							el-form-item(label="In Use")
								el-select.w-100(v-model="filters.in_use" @change="fetchData")
									el-option(label="All" value="" )
									el-option(label="Yes" value="1")
									el-option(label="No" value="0" )

						el-col(:xs="12" :sm="6")
							el-form-item(label="Platform")
								el-select.w-100(v-model="filters.platform" @change="fetchData")
									el-option(label="Show All" value="" )
									el-option(v-for="o in options.platforms" :key="o.v" :label="o.t" :value="o.v" )

						el-col(:xs="12" :sm="6")
							el-form-item(label="Type")
								el-select.w-100(v-model="filters.type" @change="fetchData")
									el-option(label="Show All" value="" )
									el-option(v-for="o in options.types" :key="o.v" :label="o.t" :value="o.v" )

					el-table(id="traffic-source-tbl" v-loading="busy" stripe="" :data="list" element-loading-text='Loading', header-row-class-name="dark-header", border='', fit='', highlight-current-row='')
						el-table-column(align="center" label="#" width="75px")
							template(slot-scope="scope")
								span() {{ scope.row.id }}
						el-table-column(align="center" label="External" width="170px")
							template(slot-scope="scope")
								span() {{ scope.row.ext_id }}
						el-table-column(align="center" label="Type")
							template(slot-scope="scope")
								el-tooltip(v-if="scope.row.traffic_source_type_id")
									template(slot="content")
										span.d-block <b>Type: </b>{{ scope.row.type.type }}
										span.d-block <b>Notes: </b>{{ scope.row.type.notes }}
										span.d-block <b>Comments: </b>{{ scope.row.type.comments }}
									span.chooseable {{ scope.row.type.type }}
								span.text-danger(v-else="") No Type
						el-table-column(align="center" label="Platform")
							template(slot-scope="scope")
								span() {{ scope.row.platform }}
						el-table-column(align="center" label="Key" width="270px" )
							template(slot-scope="scope")
								span() {{ scope.row.key }}
						el-table-column(align="center" label="Value")
							template(slot-scope="scope")
								span() {{ scope.row.value }}
						el-table-column(align="center" label="In Use" width="100px")
							template(slot-scope="scope" )
								el-tooltip(:content="scope.row.in_use ? 'In use by the system' : 'Not In Use'")
									font-awesome-icon.icon.alt.text-danger(v-if="!scope.row.in_use", :icon="['fas', 'times']")
									font-awesome-icon.icon.alt.text-success(v-else="", :icon="['fas', 'check']")
						el-table-column(align="center" label="Created At")
							template(slot-scope="scope")
								span() {{ scope.row.createdAt | formatDateTimeShortV2 }}
						el-table-column(align='center' label="Actions" width="100px")
							template(slot-scope="scope")
								// toggle
								//el-tooltip(content="Toggle")
									el-button.small-btn-action(type="warning", icon="el-icon-tickets", size="small", @click="toggleTypesModal(1, scope.row)")
								el-tooltip(content="Edit")
									el-button.small-btn-action(type="info", icon="el-icon-edit", size="small", @click="toggleModal(1, scope.row)")
								//el-tooltip(content="Delete")
									el-button.small-btn-action(type="danger", icon="el-icon-delete", size="small", @click="deleteEntry(scope.row)")
					.d-flex.justify-content-between
						b-pagination.mt-3(v-model="paginate.page", :total-rows="count", :per-page="paginate.limit", aria-controls="data-list", @change="fetchData")

						el-row.mt-4
							el-col(:span="24")
								label.label-text-gray
									b Total:&nbsp;
									| {{ count | numeral('0,0') }}

						el-row
							el-col(:span="24")
								el-select.mt-3(filterable="", v-model="paginate.limit", @change="fetchData")
									el-option(label="10 Per Page", :value="10")
									el-option(label="15 Per Page", :value="15")
									el-option(label="20 Per Page", :value="20")
									el-option(label="50 Per Page", :value="50")
									el-option(label="100 Per Page", :value="100")
									el-option(label="200 Per Page", :value="200")
		#traffic-source-modal
			el-dialog.override-width.search-dialog(:title="`${!mode?'Create new':'Edit existing'} entry${mode?` - #${currentEntry && currentEntry.id}`:''}`" :visible.sync='showTrafficSourceModal', width='75%', center='' @closed="cancelForm")
				el-row(:gutter="24")
					el-col(:span="24")
						el-row(:gutter="24")
							h5 Traffic Source Form
							p Fill the following form to add a new traffic source or edit an existing one.

				el-form(:form="form" :rules="rules" ref="traffic-source" :model="form")
					el-row(:gutter="24")
						el-col(:xs="24" :md="12" :lg="6")
							el-form-item(label="Platform" prop="platform" )
								el-select.w-100(v-model="form.platform" placeholder="Select Platform" allow-create="" filterable="")
									el-option(v-for="o in options.platforms" :key="o.v" :label="o.t" :value="o.v" )
						el-col(:xs="24" :md="12" :lg="6")
							el-form-item(label="Type" prop="type" )
								el-select.w-100(v-model="form.traffic_source_type_id" placeholder="Select Type" filterable="" prop="type")
									el-option(v-for="o in options.types" :key="o.v" :label="o.t" :value="o.v" )
						el-col(:xs="24" :md="12" :xl="6")
							el-form-item(label="Key" prop="key")
								el-input(v-model="form.key" placeholder="Enter Key")
						el-col(:xs="24" :md="12" :xl="6" prop="value")
							el-form-item(label="Value" prop="value" )
								el-input(v-model="form.value" placeholder="Enter Value")

						// el-switch for in_use, traffic_source_type_id

						el-col(:xs="24" :md="12" :xl="6")
							el-form-item(label="In Use")
								el-switch(v-model="form.in_use" active-text="Yes" inactive-text="No")


				span.dialog-footer(slot='footer')
					el-button(type='primary', @click='submit') Submit
					el-button(type='danger', @click='cancelForm') Cancel

</template>

<style lang="scss">
</style>

<script>

import {notEmpty, validateAlphaNumericAndExists, validateNumeric} from "@/utils/validate";

const $form = {
	platform: '',
	key: '',
	value: '',
	in_use: 1,
	traffic_source_type_id: null
}

const $formTypes = {
	type: '',
}

function clearEmpty(data) {
	let obj = {};
	Object.entries(data).forEach(e => {
		if(e[1] !== '' && e[1] !== null && e[1] !== undefined)
			obj[e[0]] = e[1];
	});
	return obj;
}

export default {
	name: 'traffic-sources',
	data() {
		return {
			showTrafficSourceTypesModal: false,
			showTrafficSourceModal: false,
			currentEntry: null,
			busy: false,
			mode: 0, // 0 - create, 1 - update,
			list: [],
			count: 0,
			form: JSON.parse(JSON.stringify($form)),
			rules: {
				platform: { required: true, trigger: 'blur',},
				key: { required: true, trigger: 'blur', validator: validateAlphaNumericAndExists, topic: 'traffic-sources/key', app: this,},
				value:  { required: true, trigger: 'blur', validator: notEmpty},
				traffic_source_type_id:  { required: true, trigger: 'blur', validator: validateNumeric,}
			},
			paginate: {
				page: 1,
				limit: 10,
				keyword: ''
			},
			filters: {
				in_use: 'all'
			},
			options: {
				platforms: [],
				types: []
			},
		}
	},
	mounted() {
		this.fetchData();
		this.fetchOptions();
	},
	methods: {
		cancelForm() {
			this.showTrafficSourceModal = false;
			this.currentEntry = null;
		},
		fetchOptions() {
			this.$apix.sendHttpRequest('GET', 'components/options', { filters: 'c4e5418b-e6de-4b46-9510-6cac383bf690,362aace7-fc27-4092-bcc1-715cfe5c18d9' })
					.then(options => {
						this.options.platforms= options.platform_options;
						this.options.types = options.traffic_source_types;
					})
		},
		populateForm(entry) {
			this.form.platform = entry.platform;
			this.form.key = entry.key;
			this.form.value = entry.value;
			this.form.in_use = entry.in_use;
			this.form.traffic_source_type_id = entry.traffic_source_type_id;
		},
		toggleModal(mode, entry) {
			this.mode = mode;
			this.showTrafficSourceModal = true;
			this.form = JSON.parse(JSON.stringify($form));
			if(mode) {
				this.currentEntry = entry;
				this.populateForm(entry);
				this.rules.key.original = entry.key
				this.rules.key.action = 'edit'
			} else {
				this.form = JSON.parse(JSON.stringify($form));
				this.rules.key.action = 'create'
				this.rules.key.original = undefined;
			}
		},
		toggleTypesModal(mode, entry) {
			this.mode = mode;
			this.showTrafficSourceTypesModal = true;
			this.formTypes = JSON.parse(JSON.stringify($formTypes));
			if(mode) {
				this.currentEntry = entry;
				this.populateForm(entry);
			} else {
				this.formTypes = JSON.parse(JSON.stringify($formTypes));
			}
		},
		deleteEntry(entry) {
			this.$confirm('Are you sure you want to delete this entry?', 'Warning', {
				confirmButtonText: 'OK',
				cancelButtonText: 'Cancel',
				type: 'warning'
			}).then(() => {
				this.$apix.sendHttpRequest('DELETE', 'traffic-sources/delete'+entry.id, {})
						.then(() => {
							this.fetchData();
						})
			}).catch(() => {
				this.$message({
					type: 'info',
					message: 'Delete canceled'
				});
			});
		},
		fetchData() {
			setTimeout(() => {
				const body = {...this.paginate, ...clearEmpty(this.filters)};
				this.busy = true;
				this.$apix.sendHttpRequest('GET', 'traffic-sources/list', body)
						.then(res => {
							this.busy = false
							this.list = res.rows;
							this.count = res.count;
						})
						.catch(err => {
							console.error(err);
							this.busy = false;
						})
			}, 200);
		},
		submit() {

			this.$refs['traffic-source'].validate(async valid => {
				if(valid) {
					let body = JSON.parse(JSON.stringify({...this.form}));
					delete body.type;
					if(this.mode) {
						body = JSON.parse(JSON.stringify({...this.form, original_key: this.currentEntry.key}));
						delete body.type;
						body.id = this.currentEntry.id;
					}
					this.busy = true;
					this.$apix.sendHttpRequest(this.mode ? 'PUT':'POST', 'traffic-sources/'+(this.mode ? 'update' : 'create'), body)
							.then(() => {
								this.busy = false;
								this.showTrafficSourceModal = false;
								this.fetchData();
							})
							.catch(err => {
								console.error(err);
								this.busy = false;
							})
				}
			})
		}
	}
}
</script>