<template lang="pug">
	#integration-error-root
		#integration-error-body
			el-row.text-left
				el-form()
					el-row.text-left
						h4 Integration Error Mapping
						p Integration error mapping is used to map the errors that are returned from the brand's API to the errors that we want to show the client or the affiliate. &nbsp;
							| For example, if the brand's API returns an error that says "Validation Error 123123", we can map it to "Email is invalid" or something else. The mapping supports translations to&nbsp;
							| different languages.
					el-row.mt-2(:gutter="24")
						el-col(:span="24")
							el-form-item(label="Templates")
								el-button(type="info" icon="el-icon-plus" @click="toggleModal(0)") Add
					el-form()
						el-row.mt-2(:gutter="24")
							el-col(:xs="12" :sm="8" :md="6")
								el-form-item(label="Search...")
									el-input(placeholder="Search By Keyword..."  v-model="paginate.keyword", v-debounce:300ms="fetchData")
							el-col(:xs="12" :sm="8" :md="6")
								el-form-item(label="Integration")
									el-select.w-100(multiple="" filterable="" v-model="filters.integration_id" placeholder="Select integration" @change="fetchData")
										el-option(label="- Connect To Integration -" value="")
										el-option(v-for="o of options.integration" :label="o.label" :value="o.key")
					el-table(id="integration-error-tbl" v-loading="busy" stripe="" :data="list" element-loading-text='Loading', header-row-class-name="dark-header", border='', fit='', highlight-current-row='')
						el-table-column(align="center" label="#")
							template(slot-scope="scope")
								span() {{ scope.row.id }}
						el-table-column(align="center" label="Original Value")
							template(slot-scope="scope")
								el-tooltip(v-if="scope.row.original_value.length > 50" :content="scope.row.original_value" placement="top")
									span.clickable() {{ scope.row.original_value.length > 50 ? scope.row.original_value.substring(0, 50) + '...' : scope.row.original_value }}
								span(v-else="") {{ scope.row.original_value.length > 50 ? scope.row.original_value.substring(0, 50) + '...' : scope.row.original_value }}
						el-table-column(align="center" label="Mapped Value")
							template(slot-scope="scope")
								el-tooltip(v-if="scope.row.mapped_value.length > 50" :content="scope.row.mapped_value" placement="top")
									span.clickable() {{ scope.row.mapped_value.length > 50 ? scope.row.mapped_value.substring(0, 50) + '...' : scope.row.mapped_value }}
								span(v-else="") {{ scope.row.mapped_value.length > 50 ? scope.row.mapped_value.substring(0, 50) + '...' : scope.row.mapped_value }}
						el-table-column(align="center" label="Comparison")
							template(slot-scope="scope")
								el-tag(:type="scope.row.comparison | t_status") {{ scope.row.comparison || '-' }}
						el-table-column(align="center" label="CRM Type")
							template(slot-scope="scope")
								span() {{ scope.row.crm_type }}
						el-table-column(align="center" label="Related Integration")
							template(slot-scope="scope")
								el-tooltip(v-for="i of scope.row.integrations.slice(0,5)" :key="i")
									template(slot="content")
										.d-block
												b label:&nbsp;
												| {{ hash_map.integrations[i].bn + ' - ' + hash_map.integrations[i].t + (hash_map.integrations[i].n ? ` (${hash_map.integrations[i].n})` : '') }}
										.d-block
												b type:&nbsp;
												| {{ hash_map.integrations[i].t }}
										.d-block
												b id:&nbsp;
												| {{ hash_map.integrations[i].v }}
									el-tag.mr-1() {{ hash_map.integrations[i].bn + ' - ' + hash_map.integrations[i].t + (hash_map.integrations[i].n ? ` (${hash_map.integrations[i].n})` : '') }}
								el-tooltip(v-if="scope.row.integrations.length >= 5")
									template(slot="content")
										label Showing all the integrations here
										div(v-for="i of scope.row.integrations" v-bind:class="{'mt-2': scope.row.integrations.length > 1}")
											.d-block
													b label:&nbsp;
													| {{ hash_map.integrations[i].bn + ' - ' + hash_map.integrations[i].t + (hash_map.integrations[i].n ? ` (${hash_map.integrations[i].n})` : '') }}
											.d-block
													b type:&nbsp;
													| {{ hash_map.integrations[i].t }}
											.d-block
													b id:&nbsp;
													| {{ hash_map.integrations[i].v }}
									el-tag() Show More...
						el-table-column(align="center" label="Notes")
							template(slot-scope="scope")
								el-tooltip(:content="scope.row.note" placement="top")
									span.chooseable {{!scope.row.note || scope.row.note.length === 0  ? 'Empty' : 'Hover to show'}}
						el-table-column(align="center" label="Is Used")
							template(slot-scope="scope")
								font-awesome-icon.icon.alt.big-icon.text-success(v-if="scope.row.is_used" v-b-tooltip.html.hover-right="", title="In Use", size="lg", :icon="['fas', 'plus']")
								font-awesome-icon.icon.alt.big-icon.text-danger(v-else="" v-b-tooltip.html.hover-right="", title="In Use", size="lg", :icon="['fas', 'minus']")
						el-table-column(align="center" label="Action")
							template(slot-scope="scope")
								el-tooltip(content="Edit this error mapping")
									el-button.small-btn-action(type="warning", icon="el-icon-edit", size="small", @click="toggleModal(1, scope.row)")
								el-tooltip(content="Delete this error mapping")
									el-button.small-btn-action(type="danger", icon="el-icon-remove", size="small", @click="removeEntry(scope.row.id)")
					.d-flex.justify-content-between
						b-pagination.mt-3(v-model="paginate.page", :total-rows="count", :per-page="paginate.limit", aria-controls="data-list", @change="fetchData")

		#error-mapping-modals
			el-dialog.override-width.search-dialog(:title="`${!mode?'Create new':'Edit existing'} entry${mode?` - #${currentEntry && currentEntry.id}`:''}`" :visible.sync='showIntegrationErrorModal', width='75%', center='' @closed="cancelForm")
				el-row(:gutter="24")
					el-col(:span="24")
						el-row(:gutter="24")
							h5 Integration Error Form
							p Fill the following form to create an integration error entry. These settings will override the the whole error or a part of it for the specified integration types.
				el-form(:form="form" :rules="rules" ref="cust-integration-error" :model="form")
					el-row(:gutter="24")
						el-col(:xs="24" :sm="12")
							el-form-item(prop="original_value" ref="_formOriginalValue")
								template(slot="label")
									label Original Value&nbsp;
									small.text-success() (Original value that is returned from the brand's API)
								el-input( v-model="form.original_value" placeholder="E.G. Server returned an error 422")
						el-col(:xs="24" :sm="12")
							el-form-item(prop="mapped_value" ref="_formMappedValue")
								template(slot="label")
									label Mapped Value&nbsp;
									small.text-success() (Mapped value that will be returned to the client)
								el-input( v-model="form.mapped_value" placeholder="E.G. Wrong email address")
						el-col(:xs="12" :sm="6")
							el-form-item(prop="crm_type" ref="_formCrmType")
								template(slot="label")
									label CRM Type&nbsp;
									small.text-success() (CRM type)

								el-select.w-100(v-model="form.crm_type" placeholder="Select CRM type)" @change="checkIntegrations" filterable="")
									el-option(label="- Connect To CRM Type -" value="")
									el-option(v-for="o of options.crm_types" :label="o.link" :value="o.value")

						el-col(:xs="12" :sm="6")
							el-form-item(prop="related_integration" ref="_formRelatedIntegration")
								template(slot="label")
									label Related Integration&nbsp;
									small.text-success() (Related integration)

								el-select.w-100(v-model="form.related_integration" :disabled="form.crm_type === ''" placeholder="Select integration)" filterable="" multiple="" collapse-tags="")
									el-option(label="- Connect To Integration -" value="")
									el-option(v-if="form.crm_type === o.crm_type" v-for="o of options.integration" :label="o.label" :value="o.key")
						el-col(:xs="12" :sm="6")
							el-form-item(prop="is_used" ref="_formIsUsed")
								template(slot="label")
									label Is Used&nbsp;
									small.text-success() (Is used)
								el-radio-group.w-100(size="mini" v-model="form.is_used" :fill="form.is_used === true ? '#409EFF' : form.is_used === -1 ? '#c9c9c9' : 'red'")
									el-radio-button(:label="true" ) On
									el-radio-button(:label="false") Off
									el-radio-button(:label="-1") Leave
						el-col(:xs="24")
							el-form-item(prop="note" ref="_formNote")
								template(slot="label")
									label Notes&nbsp;
									small.text-success() (Related notes)
								el-input( v-model="form.note" type="textarea" :rows="5")
					el-row.mt-4(:gutter="24")
						h5 Translations
						p You can add translations to the mapped value. This will allow you to return different values to the client based on the settings.&nbsp;
							| The mapped value must be in English so that the translator would guess and translate it correctly. Using the
					el-row.mt-2(:gutter="24")
						el-col(:span="24")
							el-tooltip(v-if="form.mapped_value === ''" content="The mapped value should not be empty if you want to translate it" placement="left")
								el-form-item(label="Click to translate the mapped value")
									el-button(type="primary" icon="el-icon-s-operation" @click="translate()" :disabled="form.mapped_value === ''") Translate
							div(v-else="")
								el-form-item(label="Click to translate the mapped value")
									el-button(type="primary" icon="el-icon-s-operation" @click="translate()" :disabled="form.mapped_value === ''") Translate

						p
							i.text-danger Note: The application will override the current values and translate the vallue of "mapped value" field to the&nbsp;
							el-tooltip(:content="supportedLanguages" placement="top")
								i
									u.text-danger.clickable supported languages.
							br
							i.text-danger Not all the languages are supported, thus, you will have to translate manually the missing ones.

					//pre {{form.translations}}
					el-row.mt-2(:gutter="24")
						el-col(v-for="l of options.languages" :xs=12 :sm="8" :md="6")
							el-form-item()
								template(slot="label")
									label {{l.t}}&nbsp;
									//el-image(:src="require('../../assets/img/flags/' + l.v + '.svg')") ({{l.v}})
								el-input(:placeholder="`Translate in ${l.t}`" v-model="form.translations[l.v]" type="textarea" :rows="3")
				span.dialog-footer(slot='footer')
					el-button(type='primary', @click='submitErrorMapping' :disabled="mandatoryFieldsNotEmpty") Submit
					el-button(type='danger', @click='cancelForm') Cancel
</template>


<style lang="scss">

</style>


<script>

import {libretranslateLanguages, libretranslateLanguagesHash} from "../../struct";

const $form = {
	id: null,
	original_value: '',
	mapped_value: '',
	related_integration: [],
	crm_type: '',
	note: '',
	is_used: false,
	translations: {},
};
const $translations = {};

function clearEmpty(data) {
	let obj = {};
	Object.entries(data).forEach(e => {
		if(e[1] !== '' && e[1] !== null && e[1] !== undefined)
			obj[e[0]] = e[1];
	});
	return obj;
}

export default {
	name: 'integration-error',
	components: {},
	filters: {
		t_status(value) {
			if(value === 'EXACT') return 'success';
			else if(value === 'CONTAINS') return 'primary';
			else return 'info';
		}
	},
	data() {
		return {
			translatePolling: null,
			showIntegrationErrorModal: false,
			currentEntry: null,
			list: [],
			count: 0,
			paginate: {
				keyword: '',
				limit: 5,
				page: 1
			},
			options: {
				integration: [],
				languages: [],
				crm_types: []
			},
			filters: {
				keyword: '',
				integration_id: ''
			},
			hash_map: {
				integrations: {}
			},
			rules: {},
			busy: false,
			mode: 0, // 0 - create, 1 - update,
			form: JSON.parse(JSON.stringify($form)),
			lang_hash: libretranslateLanguagesHash
		}
	},
	created() {
		this.$apix.sendHttpRequest('GET', 'components/options', {filters: 'a371666a-a396-4137-b92c-6c1fc201e671,9e832481-2c11-4021-ba13-0dcc5487e3f7,e91556b8-c5da-442f-a0bf-31f2ce567abe'})
				.then(options => {
					this.hash_map.integrations = {};
					const optList = options.integration_extra.map(e => {
						this.hash_map.integrations[e.v] = e;
						return Object.assign({}, {
								label: e.bn + ' - ' + e.t + (e.n ? ` (${e.n})` : ''),
								key: e.v,
								type: e.t,
								crm_type: e.ct,
							}
						)
					});
					this.options.integration = [...optList];
					this.options.crm_types = options.crm_types;
					this.options.languages = options.languages;
					options.languages.forEach(e => $translations[e.v] = '');
					this.form.translations = JSON.parse(JSON.stringify($translations));
				});
	},
	mounted() {
		this.fetchData()
	},
	computed: {
		supportedLanguages() {
			return libretranslateLanguages.join(', ');
		},
		supportedLanguagesArray() {
			return libretranslateLanguages;
		},
		mandatoryFieldsNotEmpty() {
			if(!this.form.original_value || this.form.original_value === '') return true;
			else if(!this.form.mapped_value || this.form.mapped_value === '') return true;
			return false;
		}
	},
	methods: {
		fetchData() {

			setTimeout(() => {
				const body = {...this.paginate, ...clearEmpty(this.filters)};
				this.busy = true;
				this.$apix.sendHttpRequest('GET', 'integration-error/list', body)
						.then((res) => {
							this.list = res.rows;
							this.count = res.count;
							this.busy = false;
						})
			}, 200)
		},
		submitErrorMapping() {
			this.$confirm('Are you sure you want to submit this entry?', 'Warning', {
				confirmButtonText: 'OK',
				cancelButtonText: 'Cancel',
				type: 'warning'
			}).then(() => {
				let loader = this.$loading.show();
				const method = this.mode ? 'PUT' : 'POST';
				const url = this.mode ?  `integration-error/save/${this.currentEntry.id}` : 'integration-error/save';
				this.$apix.sendHttpRequest(method, url, this.form)
						.then((res) => {
							this.$message({
								type: 'success',
								message: 'Entry was submitted successfully'
							});
							loader.hide();
							this.$message.success({title: 'API Response', message: `Entry was ${this.mode ? 'updated' : 'created'} successfully`})
							this.fetchData();
							this.cancelForm();
						})
						.catch(err => {
							console.error(err);
							loader.hide();
						})
			}).catch(() => {
				this.$message({
					type: 'info',
					message: 'Submit canceled'
				});
			});
		},
		checkIntegrations() {
			this.form.related_integration = [];
			this.options.integration.forEach(e => {
				if(e.crm_type === this.form.crm_type)
					this.form.related_integration.push(e.key);
			});
			this.form.related_integration = JSON.parse(JSON.stringify(this.form.related_integration));
		},
		translate() {
			this.$confirm('Are you sure you want to run translation process? This would take a lot of tome and override the current values to the translations of the supported languages or leave blank the ones that cannot be translated automatically! Note that the process cannot be undone once started.', 'Warning', {
				confirmButtonText: 'OK',
				cancelButtonText: 'Cancel',
				type: 'warning'
			}).then(() => {
				let loader = this.$loading.show();
				this.$apix.sendHttpRequest('POST', 'integration-error/translate', { mapped_value: this.form.mapped_value })
						.then((outRes) => {
							this.$message.info({title: 'API Response', message: outRes})
							// console.log('res.data && typeof res.data === \'object\' && Array.isArray(Object.keys(res.data))', res , typeof res === 'object' , Array.isArray(Object.keys(res)))
							const poll = setInterval(() => {
								this.$apix.sendHttpRequest('POST', 'integration-error/poll', { mapped_value: this.form.mapped_value })
										.then((result) => {
											// console.log(result)
											if(result === 'Translation ongoing...') return this.$message.info({title: 'API Response', message: result});
											else if (result && typeof result === 'object' && Array.isArray(Object.keys(result))) {
												// console.log(Object.entries(result))
												Object.entries(result).forEach(e => {
													this.form.translations[e[0]] = e[1].translatedText;
												})
												this.form.translations = JSON.parse(JSON.stringify(this.form.translations));

												this.$message.success({ title: 'API Response', message: 'Translation was successful' });
												clearInterval(poll);
												loader.hide();
											}
										})
										.catch((err) => {
											// console.log('translate', err);
											loader.hide();
										});
							}, 5000);
						})
						.catch((err) => {
							// console.log('translate', err);
							loader.hide();
						});
			})
					.catch(() => {
						this.$message({
							type: 'info',
							message: 'Translate canceled'
						});
					});
		},
		toggleModal(mode, entry) {
			this.mode = mode;
			this.showIntegrationErrorModal = true;
			this.form = JSON.parse(JSON.stringify($form));
			if(mode) {
				this.currentEntry = entry;
				this.populateForm(entry);
			} else {
				this.form = JSON.parse(JSON.stringify($form));
			}
		},
		populateForm(body) {
			this.form = JSON.parse(JSON.stringify($form));
			this.form.id = body.id;
			this.form.original_value = body.original_value;
			this.form.mapped_value = body.mapped_value;
			this.form.related_integration = body.integrations;
			this.form.crm_type = body.crm_type;
			this.form.note = body.note;
			this.form.is_used = -1;
			this.form.translations = JSON.parse(JSON.stringify($translations));
			body.error_mapping_translation.forEach(e => this.form.translations[e.language_iso] = e.translation);
		},
		removeEntry(id) {
			this.$confirm('Are you sure you want to delete this entry?', 'Warning', {
				confirmButtonText: 'OK',
				cancelButtonText: 'Cancel',
				type: 'warning'
			}).then(() => {
				const loader = this.$loading.show();
				this.$apix.sendHttpRequest('DELETE', 'integration-error/' + id, {})
						.then((res) => {
							this.$message({
								type: 'success',
								message: 'Entry was deleted successfully'
							});
							loader.hide();
							this.fetchData();
							this.$message.success({title: 'API Response', message: 'Entry was deleted successfully'});
						})
						.catch(err => {
							this.$message({
								type: 'error',
								message: 'Entry was not deleted'
							});
							loader.hide();

						})
			}).catch(() => {
				this.$message({
					type: 'info',
					message: 'Delete canceled'
				});
			});
		},
		cancelForm() {
			this.showIntegrationErrorModal = false;
			this.currentEntry = null;
			this.form = JSON.parse(JSON.stringify($form));
		},
	}
}
</script>

