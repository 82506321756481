<template lang="pug">
	#settings-root
		#settings-section
			.widget
				el-card.box-card
					.clearfix.d-flex.justify-content-start(slot="header")
						h3
							b Settings And Environment
					el-tabs(v-model="currentTab" type="" @tab-click="changeTabFunc" )
						el-tab-pane(label="Dynamic Params" name="dyn_params")
							dynamic-params.mt-3
						el-tab-pane(label="Application settings" name="app_settings")
							application-settings.mt-3
						el-tab-pane(label="Status Mapping" name="status_mapping")
							status-mapping.mt-3
						el-tab-pane(label="Language Settings" name="language_settings")
							language-settings.mt-3
						el-tab-pane(label="Traffic Sources" name="traffic_sources")
							traffic-sources.mt-3
						el-tab-pane(label="Custom Fix" name="custom_fix")
							custom-fix.mt-3
						el-tab-pane(label="Integration Error" name="integration_error")
							integration-error.mt-3
						el-tab-pane(v-if="permitCende()" label="Common Utils" name="common_utils")
							common-utils.mt-3


</template>

<style lang="scss"></style>

<script>
import dynamicParams from './dynamic-params';
import applicationSettings from './application-settings';
import StatusMapping from "./status-mapping.vue";
import customFix from "./custom-fix.vue";
import integrationError from "./integration-error.vue";
import commonUtils from "./common-utils.vue";
import languageSettings from "./language-settings.vue";
import trafficSources from "./traffic-sources.vue";

export default {
	name: 'settings',
	components: {
		StatusMapping,
		dynamicParams,
		applicationSettings,
		customFix,
		integrationError,
		languageSettings,
		commonUtils,
		trafficSources
	},
	data() {
		return {
			currentTab: this.$store.getters.CUR_SETTINGS_TAB || 'dyn_params'
		}
	},
	computed: {
		cende() {
			return this.$store.getters.CENDE;
		},
	},
	methods: {
		changeTabFunc($event) {
			// console.log(`Changed to ${$event.name}`);
			this.$store.dispatch('setCurrentSettingsTab', this.currentTab)
		},
		permitCende() {
			return this.cende == 'true';
		},
	}
}
</script>